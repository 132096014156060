import { useEffect, useState } from "react"
import axios from "../axiosSetup"
import { googleLogout } from "./googleAuth"

export default function useAuth() {
  const [user, setUser] = useState(undefined)
  const [phone, setPhone] = useState(null)
  const [name, setName] = useState("")
  const [dob, setDob] = useState(null)
  const [gender, setGender] = useState("")
  const [category, setCategory] = useState("")
  const [education, setEducation] = useState(null)

  useEffect(() => {
    auth()
  }, [])

  const auth = () => {
    axios
      .get("/user/authCheck")
      .then(data => {
        if (data.status === 200) {
          data = data.data
          setUser(user => ({
            ...user,
            email: data.email
          }))
        }
      })
      .catch(err => {
        if (err.response && err.response.status > 400) {
          setUser(null)
        }
      })
  }

  const statusUpdater = () => {
    axios
      .get("/user/status")
      .then(data => data.data)
      .then(data => {
        setUser(user => ({
          ...user,
          steps: {
            account: true,
            email: data.email,
            profile: data.profile,
            payment: data.payment
          },
          hasPassword: data.hasPassword,
          hasGoogleId: data.hasGoogleId,
          imgUrl: data.imgUrl,
          rank: data.rank ?? null
        }))
      })
  }

  const fetchProfile = () => {
    axios.get("/user/profile").then(data => {
      data = data.data
      setName(data.name)
      setPhone(data.phone)
      setDob(new Date(data.dob))
      setGender(data.gender)
      setCategory(data.category)
      if (data.category === "C") {
        setEducation({ ...data.colleging })
      } else {
        setEducation({ ...data.schooling })
      }
    })
  }

  const logout = () => {
    axios.get("/user/logout").then(data => {
      if (data.status === 200) {
        setUser(null)
        googleLogout()
      }
    })
  }

  const resetAuth = () => {
    setUser(undefined)
  }

  return {
    user: user,
    statusUpdater: statusUpdater,
    profile: {
      name: name,
      phone: phone,
      dob: dob,
      gender: gender,
      category: category,
      education: education
    },
    setProfile: {
      setName: setName,
      setPhone: setPhone,
      setDob: setDob,
      setGender: setGender,
      setCategory: setCategory,
      setEducation: setEducation
    },
    fetchProfile: fetchProfile,
    auth: auth,
    logout: logout,
    resetAuth: resetAuth
  }
}
