import { useState, useEffect } from "react"
import { Link, matchPath, useLocation } from "react-router-dom"

import { useTheme } from "@mui/material/styles"

import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import useMediaQuery from "@mui/material/useMediaQuery"
import Box from "@mui/material/Box"
import BottomNavigation from "@mui/material/BottomNavigation"

import ProfilePicture from "../../../components/profilePicture"
import LogoutButton from "../../../components/logoutButton"
import tabRules from "./tabRules"
import BottomNavBar from "./bottomNavBar"
import BottomNavButton from "./bottomNavButton"

const useRouteMatch = patterns => {
  const { pathname } = useLocation()

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i]
    const possibleMatch = matchPath(pattern, pathname)
    if (possibleMatch !== null) {
      return possibleMatch
    }
  }

  return null
}

export default function Nav({ steps, img, logout }) {
  const theme = useTheme()
  const isMediumUp = useMediaQuery(theme.breakpoints.up("sm"))
  const location = useLocation()
  const routeMatch = useRouteMatch(
    Object.values(tabRules).map(rule => {
      return { path: rule.path, end: rule.end }
    })
  )
  const [currentTab, setCurrentTab] = useState(routeMatch?.pattern?.path)

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue)
  }

  useEffect(() => {
    setCurrentTab(location.pathname)
  }, [location])

  return (
    <>
      {isMediumUp && (
        <Box
          component="nav"
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "sticky",
            top: 0,
            width: 240,
            height: "95vh",
            backgroundColor: theme.palette.background.paper,
            zIndex: 500,
            py: 3,
            px: 2
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <ProfilePicture src={img} />
            <LogoutButton onClick={logout} />
          </Box>
          {steps && (
            <List>
              {Object.entries(tabRules).map(([key, value]) => (
                <ListItem
                  key={key.split(" ").join("")}
                  disablePadding
                  sx={{ display: "block", py: 0.5 }}
                >
                  <ListItemButton
                    sx={{
                      color: theme.palette.text.secondary,
                      borderRadius: 10,
                      "&:hover": {
                        backgroundColor: theme.palette.action.hover
                      }
                    }}
                    component={Link}
                    to={value.path}
                    selected={currentTab === value.path}
                    disabled={
                      value.disabled &&
                      value.disabled.some(step => !steps[step])
                    }
                  >
                    <ListItemIcon>
                      {currentTab === value.path
                        ? value.icon.selected
                        : value.icon.default}
                    </ListItemIcon>
                    <ListItemText primary={key} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      )}
      {!isMediumUp && (
        <BottomNavBar>
          <BottomNavigation
            value={currentTab}
            onChange={handleChange}
            showLabels
            sx={{ p: 1 }}
          >
            {steps &&
              Object.entries(tabRules).map(([key, value]) => (
                <BottomNavButton
                  disableRipple
                  key={key.split(" ").join("")}
                  title={key}
                  label={key !== "Sample Papers" ? key : "Prepapers"}
                  value={value.path}
                  component={Link}
                  to={value.path}
                  icon={
                    currentTab === value.path
                      ? value.icon.selected
                      : value.icon.default
                  }
                  selected={currentTab === value.path}
                  disabled={
                    value.disabled && value.disabled.some(step => !steps[step])
                  }
                />
              ))}
          </BottomNavigation>
        </BottomNavBar>
      )}
    </>
  )
}
